import React from 'react';
import { Link, Chip } from '@mui/material';
import styled from '@emotion/styled';
import AddProcess from 'ui/pages/processes/AddProcess';
import { useNavigate } from 'react-router-dom';
import {
    MaterialRef,
    MaterialService,
    PageableProcesses,
    Process,
    ProcessService,
    type ProcessStatistic,
    ProcessStatisticsService,
    ProductRef,
    ProductService,
    Role,
    Status,
} from 'ui/api/gen';
import { useSpinner } from 'ui/hooks/spinner';
import { formatDateToISOFormat, secondsToHMS } from 'ui/utils/date';
import { ApplicationContext } from 'ui/context/ApplicationContext';
import ActionMenu from 'ui/components/actionmenu/ActionMenu';
import { STATUS_LABEL } from 'ui/utils/enums';
import { initialFilters } from 'ui/utils/datagrid';
import { useErrorHandler } from 'ui/utils/hooks';
import TableComponent from 'ui/components/grid/TableComponent';
import useDeepCompareEffect from 'use-deep-compare-effect';
import RestrictedAddButton from 'ui/components/button/RestrictedAddButton';
import CopyProcess from 'ui/pages/processes/CopyProcess';

const Container = styled('div')`
    margin: 5px;
`;

export function ProcessesPage() {
    const [filters, setFilters] = React.useState(initialFilters);
    const [open, setOpen] = React.useState(false);
    const [copyProcess, setCopyProcess] = React.useState(false);
    const [pageableProcesses, setPageableProcesses] = React.useState<PageableProcesses>();
    const [process, setProcess] = React.useState<Process | undefined>();
    const [products, setProducts] = React.useState<ProductRef[]>([]);
    const [materials, setMaterials] = React.useState<MaterialRef[]>([]);
    const [statistics, setProcessStatistics] = React.useState<ProcessStatistic[]>([]);

    const withSpinner = useSpinner();
    const handleError = useErrorHandler();
    const navigate = useNavigate();
    const { state } = React.useContext(ApplicationContext);

    const { organization } = state;

    const toggleOverlay = React.useCallback(() => {
        setOpen((value) => !value);
    }, []);

    const fetchProcesses = React.useCallback(() => {
        const fetch = async () => {
            try {
                const { pageSize, pageIndex, sort, columnFilters } = filters;
                const pageableProcessesResponse: PageableProcesses = await ProcessService.getProcesses(
                    pageSize,
                    pageIndex,
                    sort,
                    organization?.id,
                    columnFilters.find((i) => i.id === 'name')?.value,
                    columnFilters.find((i) => i.id === 'status')?.value,
                    columnFilters.find((i) => i.id === 'productId')?.value,
                );

                setPageableProcesses(pageableProcessesResponse);

                const processesIds = pageableProcessesResponse?.result?.map((item) => item.id);
                const processStatisticsResponse: Array<ProcessStatistic> =
                    await ProcessStatisticsService.getProcessStatistics(organization?.id, processesIds);
                setProcessStatistics(processStatisticsResponse);
            } catch (e) {
                console.error('An error occurred:', e);
                handleError();
            }
        };
        withSpinner(fetch);
    }, [filters, withSpinner, handleError, organization?.id]);

    React.useEffect(() => {
        const initialize = async () => {
            try {
                const productsRef: ProductRef[] = await ProductService.getProductsRef(organization?.id);
                setProducts(productsRef);

                const materialsRef: MaterialRef[] = await MaterialService.getMaterialsRef(organization?.id);
                setMaterials(materialsRef);
            } catch (e) {
                console.error('An error occurred:', e);
                handleError();
            }
        };
        withSpinner(initialize);
    }, [withSpinner, handleError, organization?.id]);

    const handleCopy = React.useCallback(
        ({ original: { id } }) => {
            setProcess(pageableProcesses?.result.find((elem) => elem.id === id));
            setCopyProcess(true);
        },
        [pageableProcesses],
    );

    const handleUpdate = React.useCallback(
        ({ original: { id } }) => {
            setProcess(pageableProcesses?.result.find((elem) => elem.id === id));
            toggleOverlay();
        },
        [toggleOverlay, pageableProcesses],
    );

    const handleDelete = React.useCallback(
        ({ original: { id } }) => {
            const update = async () => {
                try {
                    await ProcessService.deleteProcesses(id);

                    await fetchProcesses();
                } catch (e) {
                    console.error('An error occurred:', e);
                    handleError();
                }
            };

            withSpinner(update);
        },
        [fetchProcesses, withSpinner, handleError],
    );

    const handleNavigate = React.useCallback(
        ({ original: { id } }) => {
            navigate(`/processes/${id}`);
        },
        [navigate],
    );

    const handleClose = React.useCallback(() => {
        setOpen(false);
        setProcess(undefined);
    }, []);

    const handleCloseCopyProcess = React.useCallback(() => {
        setCopyProcess(false);
        setProcess(undefined);
    }, []);

    const handleSubmit = React.useCallback(
        (values: Process) => {
            const save = async () => {
                try {
                    const input = {
                        id: values.id,
                        description: values.description,
                        name: values.name,
                        materialIds: values.materialIds,
                        productId: values.productId,
                        organizationId: organization.id,
                        status: values.status || Status.DRAFT,
                    };

                    const callback = values.id ? ProcessService.putProcesses : ProcessService.postProcesses;

                    // @ts-ignore
                    await callback(input);

                    await fetchProcesses();
                } catch (e) {
                    console.error('An error occurred:', e);
                    handleError();
                } finally {
                    setProcess(undefined);
                    toggleOverlay();
                }
            };

            withSpinner(save);
        },
        [toggleOverlay, withSpinner, organization, fetchProcesses, handleError],
    );

    const actions = React.useMemo(
        () => ({
            PREVIEW: { label: 'Прегледај', callback: handleNavigate },
            UPDATE: { label: 'Ажурирај', callback: handleUpdate, roles: [Role.PM_MANAGER, Role.PM_ADMIN] },
            COPY: { label: 'Копирај', callback: handleCopy, roles: [Role.PM_MANAGER, Role.PM_ADMIN] },
            DELETE: { label: 'Избриши', callback: handleDelete, roles: [Role.PM_MANAGER, Role.PM_ADMIN] },
        }),
        [handleNavigate, handleUpdate, handleDelete, handleCopy],
    );

    const columns = React.useMemo(
        () => [
            {
                accessorKey: 'name',
                header: 'Назив',
                flex: 2,
                Cell: ({ row, cell }) => (
                    // eslint-disable-next-line jsx-a11y/anchor-is-valid
                    <Link onClick={() => handleNavigate(row)} color="primary" component="button">
                        {cell.getValue()}
                    </Link>
                ),
            },
            {
                accessorKey: 'productId',
                header: 'Продукт',
                filterVariant: 'autocomplete',
                muiFilterAutocompleteProps: {
                    options: products.map(({ id, name }) => ({
                        value: id,
                        label: name,
                    })),
                },

                flex: 1,
                accessorFn: ({ productId }) => products.find((item) => item.id === productId)?.name,
            },
            {
                accessorKey: 'average',
                header: 'Просечно време',
                enableColumnFilter: false,
                enableSorting: false,
                type: 'number',
                flex: 1,
                accessorFn: ({ average }) => secondsToHMS(average),
            },
            {
                accessorKey: 'minimum',
                header: 'Минимално време',
                type: 'number',
                enableColumnFilter: false,
                enableSorting: false,
                flex: 1,
                accessorFn: ({ minimum }) => secondsToHMS(minimum),
            },
            {
                accessorKey: 'maximum',
                header: 'Максимално време',
                type: 'number',
                enableColumnFilter: false,
                enableSorting: false,
                flex: 1,
                accessorFn: ({ maximum }) => secondsToHMS(maximum),
            },
            {
                accessorKey: 'status',
                header: 'Статус',
                flex: 1,
                filterVariant: 'select',
                filterSelectOptions: [
                    { value: Status.ACTIVE, label: STATUS_LABEL[Status.ACTIVE] },
                    { value: Status.DRAFT, label: STATUS_LABEL[Status.DRAFT] },
                ],
                Cell: ({ row }) => {
                    const label = STATUS_LABEL[row.original.status];
                    const color = row.original.status === Status.DRAFT ? undefined : 'success';

                    return <Chip color={color} label={label} />;
                },
            },
            {
                accessorKey: 'createdAt',
                header: 'Датум на креирање',
                enableColumnFilter: false,
                flex: 1,
                accessorFn: ({ createdAt }) => formatDateToISOFormat(createdAt),
            },
            {
                accessorKey: 'Акции',
                header: '',
                id: 'actions',
                enableColumnFilter: false,
                enableSorting: false,
                size: 10,
                Cell: ({ row }) => <ActionMenu params={row} actions={actions} />,
            },
        ],
        [products, actions, handleNavigate],
    );

    const rows = React.useMemo(
        () =>
            pageableProcesses?.result.map(({ id, name, status, createdAt, productId }) => {
                const statistic = statistics.find((item) => item.processId === id);
                return {
                    id,
                    name,
                    status,
                    average: statistic?.avg,
                    minimum: statistic?.min,
                    maximum: statistic?.max,
                    createdAt,
                    productId,
                };
            }),
        [pageableProcesses, statistics],
    );

    useDeepCompareEffect(() => {
        const initialize = async () => {
            try {
                await fetchProcesses();
            } catch (e) {
                console.error('An error occurred:', e);
                handleError();
            }
        };
        withSpinner(initialize);
    }, [organization?.id, withSpinner, filters, handleError]);

    return (
        <Container>
            <RestrictedAddButton
                onClick={toggleOverlay}
                title="Додади процес"
                roles={[Role.PM_MANAGER, Role.PM_ADMIN]}
            />
            <TableComponent
                pageSize={filters.pageSize}
                pageIndex={filters.pageIndex}
                pageCount={pageableProcesses?.page?.totalPages}
                rowCount={pageableProcesses?.page?.totalElements}
                rows={rows}
                columns={columns}
                setFilters={setFilters}
            />
            <AddProcess
                open={open}
                onSubmit={handleSubmit}
                handleClose={handleClose}
                process={process}
                products={products}
                materials={materials}
            />
            <CopyProcess
                open={copyProcess}
                onSubmit={handleSubmit}
                handleClose={handleCloseCopyProcess}
                process={process}
                products={products}
                materials={materials}
            />
        </Container>
    );
}

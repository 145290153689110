import React from 'react';
import InviteUser from 'ui/pages/users/invitations/InviteUser';
import { InvitationService, OrganizationRef, OrganizationService, PageableInvitations, Role } from 'ui/api/gen';
import { useSpinner } from 'ui/hooks/spinner';
import { formatDateToISOFormat } from 'ui/utils/date';
import { ROLE_LABEL } from 'ui/utils/enums';
import ActionMenu from 'ui/components/actionmenu/ActionMenu';
import { useErrorHandler, useIsMounted } from 'ui/utils/hooks';
import { initialFilters } from 'ui/utils/datagrid';
import TableComponent from 'ui/components/grid/TableComponent';
import RestrictedAddButton from 'ui/components/button/RestrictedAddButton';
import useDeepCompareEffect from 'use-deep-compare-effect';

export function InvitationsTab() {
    const [show, setShow] = React.useState(false);

    const isMounted = useIsMounted();
    const handleError = useErrorHandler();
    const withSpinner = useSpinner();
    const [filters, setFilters] = React.useState(initialFilters);
    const [pageable, setPageable] = React.useState<PageableInvitations>();

    const [organizations, setOrganizations] = React.useState<OrganizationRef[]>([]);

    const toggleOverlay = React.useCallback(() => {
        setShow((value) => !value);
    }, []);

    const handleDelete = React.useCallback(
        ({ original: { id } }) => {
            const callback = async () => {
                try {
                    await InvitationService.deleteInvitations(id);
                    const response: PageableInvitations = await InvitationService.getInvitations();

                    if (isMounted) {
                        setPageable(response);
                    }
                } catch (e) {
                    console.error('An error occurred:', e);
                    handleError();
                }
            };
            withSpinner(callback);
        },
        [withSpinner, isMounted, handleError],
    );

    const handleResend = React.useCallback(
        ({ original: { id } }) => {
            const resend = async () => {
                try {
                    await InvitationService.putInvitations(id);
                } catch (e) {
                    console.error('An error occurred:', e);
                    handleError();
                }
            };
            withSpinner(resend);
        },
        [withSpinner, handleError],
    );

    const actions = React.useMemo(
        () => ({
            DELETE: {
                label: 'Избриши',
                callback: handleDelete,
                roles: [Role.PM_MANAGER, Role.PM_ADMIN, Role.PM_CLUSTER],
            },
            RESEND: {
                label: 'Испрати повторно',
                callback: handleResend,
                roles: [Role.PM_MANAGER, Role.PM_ADMIN, Role.PM_CLUSTER],
            },
        }),
        [handleResend, handleDelete],
    );

    const columns = React.useMemo(
        () => [
            {
                accessorKey: 'organizationId',
                header: 'Организација',
                filterVariant: 'autocomplete',
                muiFilterAutocompleteProps: {
                    options: organizations?.map(({ id, name }) => ({
                        value: id,
                        label: name,
                    })),
                },

                flex: 2,
                accessorFn: ({ organizationId }) => organizations?.find((item) => item.id === organizationId)?.name,
            },
            { accessorKey: 'email', header: 'Емаил', flex: 2 },
            { accessorKey: 'role', header: 'Улога', flex: 1, accessorFn: ({ role }) => ROLE_LABEL[role] },
            {
                accessorKey: 'createdAt',
                header: 'Датум',
                flex: 1,
                enableSorting: false,
                accessorFn: ({ createdAt }) => formatDateToISOFormat(createdAt),
            },
            {
                accessorKey: 'actions',
                header: '',
                size: 1,
                id: 'actions',
                enableColumnFilter: false,
                enableSorting: false,
                Cell: ({ row }) => <ActionMenu params={row} actions={actions} />,
            },
        ],
        [actions, organizations],
    );

    const rows = React.useMemo(
        () =>
            pageable?.result.map(({ id, email, role, organizationId, createdAt }) => ({
                id,
                email,
                role,
                createdAt,
                organizationId,
            })),
        [pageable],
    );

    const handleInviteUser = React.useCallback(
        (values) => {
            const save = async () => {
                try {
                    await InvitationService.postInvitations(values);

                    const { pageSize, pageIndex, sort } = filters;
                    const response: PageableInvitations = await InvitationService.getInvitations(
                        pageSize,
                        pageIndex,
                        sort,
                    );

                    if (isMounted) {
                        setPageable(response);
                    }
                } catch (e) {
                    console.error('An error occurred:', e);
                    handleError();
                } finally {
                    toggleOverlay();
                }
            };
            withSpinner(save);
        },
        [isMounted, toggleOverlay, withSpinner, filters, handleError],
    );

    React.useEffect(() => {
        const initialize = async () => {
            try {
                const organizationsRef = await OrganizationService.getOrganizationsRef();

                if (isMounted) {
                    setOrganizations(organizationsRef);
                }
            } catch (e) {
                console.error('An error occurred:', e);
                handleError();
            }
        };
        withSpinner(initialize);
    }, [withSpinner, isMounted, handleError]);

    useDeepCompareEffect(() => {
        const initialize = async () => {
            try {
                const { pageSize, pageIndex, sort, columnFilters } = filters;
                const pageableInvitations: PageableInvitations = await InvitationService.getInvitations(
                    pageSize,
                    pageIndex,
                    sort,
                    columnFilters.find((i) => i.id === 'organizationId')?.value,
                    columnFilters.find((i) => i.id === 'role')?.value,
                    columnFilters.find((i) => i.id === 'email')?.value,
                );

                if (isMounted) {
                    setPageable(pageableInvitations);
                }
            } catch (e) {
                console.error('An error occurred:', e);
                handleError();
            }
        };
        withSpinner(initialize);
    }, [withSpinner, isMounted, filters, handleError]);

    return (
        <div>
            <RestrictedAddButton
                onClick={toggleOverlay}
                title="Додади Корисник"
                roles={[Role.PM_MANAGER, Role.PM_ADMIN, Role.PM_CLUSTER]}
            />
            <TableComponent
                pageSize={filters.pageSize}
                pageIndex={filters.pageIndex}
                pageCount={pageable?.page?.totalPages}
                rowCount={pageable?.page?.totalElements}
                rows={rows}
                columns={columns}
                setFilters={setFilters}
            />
            <InviteUser
                open={show}
                handleClose={toggleOverlay}
                onSubmit={handleInviteUser}
                organizations={organizations}
            />
        </div>
    );
}

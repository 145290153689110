const getFilename = (response, defaultFilename) => {
    const contentDisposition = response.headers.get('Content-Disposition');

    if (contentDisposition) {
        const filenameMatch = contentDisposition.match(/filename="?([^"]+)"?/);
        if (filenameMatch && filenameMatch[1]) {
            return filenameMatch[1];
        }
    }

    return defaultFilename;
};

export const downloadFile = async ({ url, fileName, token, handleError }) => {
    try {
        const response = await fetch(url, {
            method: 'GET',
            headers: {
                Authorization: `Bearer ${token}`,
                Accept: 'application/vnd.ms-excel',
            },
        });

        const blob = await response.blob();

        const file = getFilename(response, fileName);

        const downloadUrl = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = downloadUrl;
        a.download = file;
        a.style.display = 'none';

        document.body.appendChild(a);
        a.click();

        document.body.removeChild(a);
        window.URL.revokeObjectURL(downloadUrl);
    } catch (error) {
        handleError(error);
    }
};

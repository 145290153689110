import React from 'react';
import { styled } from '@mui/material';
import AddOrganization from 'ui/pages/organizations/AddOrganization';
import { useNavigate } from 'react-router-dom';
import { Organization, OrganizationService, PageableOrganizations, Role } from 'ui/api/gen';
import { formatDateToISOFormat } from 'ui/utils/date';
import { useSpinner } from 'ui/hooks/spinner';
import { initialFilters } from 'ui/utils/datagrid';
import ActionMenu from 'ui/components/actionmenu/ActionMenu';
import { useErrorHandler } from 'ui/utils/hooks';
import TableComponent from 'ui/components/grid/TableComponent';
import RestrictedAddButton from 'ui/components/button/RestrictedAddButton';
import useDeepCompareEffect from 'use-deep-compare-effect';

const Container = styled('div')`
    margin: 5px;
`;

export function OrganizationsPage() {
    const [show, setShow] = React.useState(false);
    const [pageable, setPageable] = React.useState<PageableOrganizations>();

    const [filters, setFilters] = React.useState(initialFilters);
    const navigate = useNavigate();
    const withSpinner = useSpinner();
    const handleError = useErrorHandler();
    const [current, setCurrent] = React.useState<Organization | undefined>();

    const handleRowClick = React.useCallback(
        ({ original: { id } }) => {
            navigate(`/organizations/${id}`);
        },
        [navigate],
    );

    const handleDelete = React.useCallback(
        ({ original: { id } }) => {
            const update = async () => {
                try {
                    await OrganizationService.deleteOrganizations(id);
                    const { pageSize, pageIndex, sort, columnFilters } = filters;
                    const response = await OrganizationService.getOrganizations(
                        pageSize,
                        pageIndex,
                        sort,
                        columnFilters.find((i) => i.id === 'name')?.value,
                        columnFilters.find((i) => i.id === 'email')?.value,
                        columnFilters.find((i) => i.id === 'taxNumber')?.value,
                    );
                    setPageable(response);
                } catch (e) {
                    console.error('An error occurred:', e);
                    handleError();
                }
            };

            withSpinner(update);
        },
        [withSpinner, handleError, filters],
    );

    const handleUpdate = React.useCallback(
        ({ original: { id } }) => {
            setCurrent(pageable?.result.find((elem) => elem.id === id));
            setShow(true);
        },
        [pageable, setShow],
    );

    const actions = React.useMemo(
        () => ({
            PREVIEW: { label: 'Прегледај', callback: handleRowClick },
            UPDATE: {
                label: 'Ажурирај',
                callback: handleUpdate,
                roles: [Role.PM_MANAGER, Role.PM_ADMIN, Role.PM_CLUSTER],
            },
            DELETE: {
                label: 'Избриши',
                callback: handleDelete,
                roles: [Role.PM_MANAGER, Role.PM_ADMIN, Role.PM_CLUSTER],
            },
        }),
        [handleRowClick, handleUpdate, handleDelete],
    );

    const columns = [
        { accessorKey: 'name', header: 'Име на компанијата', flex: 1 },
        { accessorKey: 'email', header: 'Емаил', flex: 1 },
        { accessorKey: 'taxNumber', header: 'Даночен број', flex: 1 },
        {
            accessorKey: 'address',
            header: 'Адреса',
            flex: 1,
            // enableSorting: false,
            enableColumnFilter: false,
            accessorFn: ({ address }) => `${address?.addressLine}, ${address?.city}, ${address?.zipCode}`,
        },
        {
            accessorKey: 'createdAt',
            header: 'Датум',
            flex: 1,
            enableColumnFilter: false,
            accessorFn: ({ createdAt }) => formatDateToISOFormat(createdAt),
        },
        {
            accessorKey: 'actions',
            header: '',
            size: 1,
            id: 'actions',
            enableColumnFilter: false,
            enableSorting: false,
            Cell: ({ row }) => <ActionMenu params={row} actions={actions} />,
        },
    ];

    const rows = React.useMemo(
        () =>
            pageable?.result.map(({ id, name, taxNumber, email, address, createdAt }) => ({
                id,
                name,
                taxNumber,
                email,
                address,
                createdAt,
            })),
        [pageable],
    );

    const toggleOverlay = React.useCallback(() => {
        setShow((value) => !value);
    }, []);

    const fetchOrganizations = React.useCallback(() => {
        const fetch = async () => {
            try {
                const { pageSize, pageIndex, sort, columnFilters } = filters;
                const response = await OrganizationService.getOrganizations(
                    pageSize,
                    pageIndex,
                    sort,
                    columnFilters.find((i) => i.id === 'name')?.value,
                    columnFilters.find((i) => i.id === 'email')?.value,
                    columnFilters.find((i) => i.id === 'taxNumber')?.value,
                );
                setPageable(response);
            } catch (e) {
                console.error('An error occurred:', e);
                handleError();
            }
        };
        withSpinner(fetch);
    }, [filters, withSpinner, handleError]);

    useDeepCompareEffect(() => {
        const initialize = async () => {
            await fetchOrganizations();
        };

        initialize();
    }, [filters]); // Ensure effect runs when fetchOrganizations changes

    const handleAddOrganization = React.useCallback(
        (values) => {
            const save = async () => {
                try {
                    const callback = values.id
                        ? OrganizationService.putOrganizations
                        : OrganizationService.postOrganizations;

                    await callback(values);

                    await fetchOrganizations();
                } catch (e) {
                    console.error('An error occurred:', e);
                    handleError();
                } finally {
                    toggleOverlay();
                }
            };
            withSpinner(save);
        },
        [withSpinner, toggleOverlay, fetchOrganizations, handleError],
    );

    return (
        <div>
            <Container>
                <RestrictedAddButton
                    onClick={toggleOverlay}
                    title="Додади Организација"
                    roles={[Role.PM_ADMIN, Role.PM_CLUSTER]}
                />
                <AddOrganization
                    open={show}
                    handleClose={toggleOverlay}
                    onSubmit={handleAddOrganization}
                    organization={current}
                />
                <TableComponent
                    pageSize={filters.pageSize}
                    pageIndex={filters.pageIndex}
                    rowCount={pageable?.page?.totalElements}
                    pageCount={pageable?.page?.totalPages}
                    rows={rows}
                    columns={columns}
                    setFilters={setFilters}
                />
            </Container>
        </div>
    );
}

import * as React from 'react';
import { useSpinner } from 'ui/hooks/spinner';
import { EmployeeService, Material, MaterialService, PageableMaterials, Role } from 'ui/api/gen';
import { formatDateToISOFormat } from 'ui/utils/date';
import AddMaterial from 'ui/pages/configuration/materials/AddMaterial';
import { useDownloadFile, useErrorHandler, useIsMounted } from 'ui/utils/hooks';
import ActionMenu from 'ui/components/actionmenu/ActionMenu';
import { initialFilters } from 'ui/utils/datagrid';
import TableComponent from 'ui/components/grid/TableComponent';
import RestrictedAddButton from 'ui/components/button/RestrictedAddButton';
import useDeepCompareEffect from 'use-deep-compare-effect';
import { ApplicationContext } from 'ui/context/ApplicationContext';
import { Button } from '@mui/material';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { HiddenInput } from 'ui/components/hiddeninput/HiddenInput';
import Typography from '@mui/material/Typography';

const Materials = () => {
    const [show, setShow] = React.useState(false);
    const [current, setCurrent] = React.useState<Material | undefined>();
    const [filters, setFilters] = React.useState(initialFilters);
    const [pageable, setPageable] = React.useState<PageableMaterials>();
    const [fileName, setFileName] = React.useState('');

    const { state } = React.useContext(ApplicationContext);
    const { organization } = state;

    const withSpinner = useSpinner();
    const handleError = useErrorHandler();
    const isMounted = useIsMounted();
    const { download } = useDownloadFile();

    const toggleOverlay = React.useCallback(() => {
        setShow((value) => !value);
    }, []);

    const handleDownloadFile = React.useCallback(() => {
        download({
            url: `api/v1/materials/download?organizationId=${organization?.id}`,
            fileName: 'materials.xlsx',
        });
    }, [organization, download]);

    const fetchMaterials = React.useCallback(async () => {
        try {
            const { pageSize, pageIndex, sort, columnFilters } = filters;

            const response = await MaterialService.getMaterials(
                pageSize,
                pageIndex,
                sort,
                organization?.id,
                columnFilters.find((i) => i.id === 'name')?.value,
            );
            setPageable(response);
        } catch (e) {
            console.error('An error occurred while fetching employees:', e);
            handleError();
        }
    }, [handleError, filters, organization?.id]);

    const handleDelete = React.useCallback(
        ({ original: { id } }) => {
            const callback = async () => {
                try {
                    await MaterialService.deleteMaterials(id);
                    await fetchMaterials();
                } catch (e) {
                    console.error('An error occurred:', e);
                    handleError();
                }
            };
            withSpinner(callback);
        },
        [fetchMaterials, withSpinner, handleError],
    );

    const handleFileUpload = React.useCallback(
        (event) => {
            const file = event.target.files?.[0];
            if (!file) return;

            setFileName(file.name);

            const fetchData = async () => {
                try {
                    await MaterialService.postMaterialsUpload(organization.id, { file });
                    await fetchMaterials();
                } catch (error) {
                    console.error('File upload failed:', error);
                    handleError();
                }
            };

            withSpinner(fetchData);
        },
        [handleError, withSpinner, organization?.id, fetchMaterials],
    );

    const handleClose = React.useCallback(() => {
        setShow(false);
        setCurrent(undefined);
    }, []);

    const handleUpdate = React.useCallback(
        ({ original: { id } }) => {
            setCurrent(pageable?.result.find((elem) => elem.id === id));
            toggleOverlay();
        },
        [pageable, toggleOverlay],
    );

    const actions = React.useMemo(
        () => ({
            UPDATE: { label: 'Ажурирај', callback: handleUpdate, roles: [Role.PM_MANAGER, Role.PM_ADMIN] },
            DELETE: { label: 'Избриши', callback: handleDelete, roles: [Role.PM_MANAGER, Role.PM_ADMIN] },
        }),
        [handleDelete, handleUpdate],
    );

    const columns = React.useMemo(
        () => [
            { accessorKey: 'name', header: 'Назив', flex: 2 },
            {
                accessorKey: 'createdAt',
                header: 'Датум',
                flex: 2,
                enableColumnFilter: false,
                accessorFn: ({ createdAt }) => formatDateToISOFormat(createdAt),
            },
            {
                accessorKey: 'actions',
                header: '',
                id: 'actions',
                enableColumnFilter: false,
                enableSorting: false,
                size: 1,
                Cell: ({ row }) => <ActionMenu params={row} actions={actions} />,
            },
        ],
        [actions],
    );

    const rows = React.useMemo(
        () =>
            pageable?.result.map(({ id, name, createdAt }) => ({
                id,
                name,
                createdAt,
            })),
        [pageable],
    );

    useDeepCompareEffect(() => {
        const initialize = async () => {
            try {
                const { pageSize, pageIndex, sort, columnFilters } = filters;

                const response = await MaterialService.getMaterials(
                    pageSize,
                    pageIndex,
                    sort,
                    organization?.id,
                    columnFilters.find((i) => i.id === 'name')?.value,
                );
                setPageable(response);
            } catch (e) {
                console.error('An error occurred:', e);
                handleError();
            }
        };
        withSpinner(initialize);
    }, [withSpinner, filters, handleError, organization?.id]);

    const onSubmit = React.useCallback(
        (values) => {
            const save = async () => {
                try {
                    await MaterialService.postMaterials(values);

                    const { pageSize, pageIndex, sort, columnFilters } = filters;

                    const response = await MaterialService.getMaterials(
                        pageSize,
                        pageIndex,
                        sort,
                        organization?.id,
                        columnFilters.find((i) => i.id === 'name')?.value,
                    );
                    if (isMounted) {
                        setPageable(response);
                    }
                } catch (e) {
                    console.error('An error occurred:', e);
                    handleError();
                } finally {
                    handleClose();
                }
            };
            withSpinner(save);
        },
        [organization?.id, isMounted, withSpinner, handleError, handleClose, filters],
    );

    return (
        <div>
            <div>
                <Button
                    component="label"
                    role={undefined}
                    variant="contained"
                    tabIndex={-1}
                    startIcon={<CloudDownloadIcon />}
                    onClick={handleDownloadFile}
                >
                    Превземи фајл
                </Button>
                <span>&nbsp;</span>
                <Button
                    component="label"
                    role={undefined}
                    variant="contained"
                    tabIndex={-1}
                    startIcon={<CloudUploadIcon />}
                >
                    Прикачи фајл
                    <HiddenInput type="file" onChange={handleFileUpload} />
                </Button>
                {fileName && (
                    <Typography component="span" style={{ marginTop: '10px' }}>
                        File Uploaded: {fileName}
                    </Typography>
                )}
            </div>
            <br />
            <RestrictedAddButton
                onClick={toggleOverlay}
                title="Додади материјал"
                roles={[Role.PM_ADMIN, Role.PM_MANAGER]}
            />
            <TableComponent
                pageSize={filters.pageSize}
                pageIndex={filters.pageIndex}
                rowCount={pageable?.page?.totalElements}
                pageCount={pageable?.page?.totalPages}
                rows={rows}
                columns={columns}
                setFilters={setFilters}
            />
            <AddMaterial open={show} handleClose={toggleOverlay} onSubmit={onSubmit} material={current} />
        </div>
    );
};

export default Materials;

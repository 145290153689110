/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Employee } from '../models/Employee';
import type { EmployeeRef } from '../models/EmployeeRef';
import type { PageableEmployees } from '../models/PageableEmployees';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class EmployeeService {
    /**
     * Get employees excel
     * Get employees excel
     * @param organizationId
     * @returns binary Successful operation
     * @throws ApiError
     */
    public static getEmployeesDownload(
        organizationId: string,
    ): CancelablePromise<Blob> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/employees/download',
            query: {
                'organizationId': organizationId,
            },
        });
    }
    /**
     * Add employees
     * Add a bulk set of employees
     * @param organizationId
     * @param formData
     * @returns void
     * @throws ApiError
     */
    public static postEmployeesUpload(
        organizationId: string,
        formData: {
            file: Blob;
        },
    ): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/employees/upload',
            query: {
                'organizationId': organizationId,
            },
            formData: formData,
            mediaType: 'multipart/form-data',
        });
    }
    /**
     * Updates the employee
     * Updates the employee
     * @param requestBody Updates employee by id
     * @returns Employee Successful operation
     * @throws ApiError
     */
    public static putEmployees(
        requestBody: Employee,
    ): CancelablePromise<Employee> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/employees',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Add a new employee
     * Add a new employee
     * @param requestBody Create a new employee
     * @returns Employee Successful operation
     * @throws ApiError
     */
    public static postEmployees(
        requestBody: Employee,
    ): CancelablePromise<Employee> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/employees',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Get employees
     * Get employees
     * @param size The number of items to include in a page
     * @param page The page number
     * @param sort Order a result by given attribute (Example: 'name,asc')
     * @param firstName first name of the employee
     * @param lastName last name of the employee
     * @param uid uid of the employee
     * @param organizationId organization id
     * @returns PageableEmployees Successful operation
     * @throws ApiError
     */
    public static getEmployees(
        size?: number,
        page?: number,
        sort?: string,
        firstName?: string,
        lastName?: string,
        uid?: string,
        organizationId?: string,
    ): CancelablePromise<PageableEmployees> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/employees',
            query: {
                'size': size,
                'page': page,
                'sort': sort,
                'firstName': firstName,
                'lastName': lastName,
                'uid': uid,
                'organizationId': organizationId,
            },
        });
    }
    /**
     * Get employees
     * Get employees
     * @param organizationId
     * @returns EmployeeRef Successful operation
     * @throws ApiError
     */
    public static getEmployeesRef(
        organizationId?: string,
    ): CancelablePromise<Array<EmployeeRef>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/employees/ref',
            query: {
                'organizationId': organizationId,
            },
        });
    }
    /**
     * Deletes a employees
     * delete a employees
     * @param id Employees id to delete
     * @returns void
     * @throws ApiError
     */
    public static deleteEmployees(
        id: string,
    ): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/employees/{id}',
            path: {
                'id': id,
            },
            errors: {
                404: `id not found`,
            },
        });
    }
}

import { Button } from '@mui/material';
import * as React from 'react';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import { HiddenInput } from 'ui/components/hiddeninput/HiddenInput';
import Typography from '@mui/material/Typography';
import AddEmployee from 'ui/pages/configuration/employees/AddEmployee';
import { Employee, EmployeeService, PageableEmployees, Role } from 'ui/api/gen';
import { useSpinner } from 'ui/hooks/spinner';
import { formatDateToISOFormat } from 'ui/utils/date';
import ActionMenu from 'ui/components/actionmenu/ActionMenu';
import { useErrorHandler, useIsMounted, useDownloadFile } from 'ui/utils/hooks';
import { initialFilters } from 'ui/utils/datagrid';
import TableComponent from 'ui/components/grid/TableComponent';
import RestrictedAddButton from 'ui/components/button/RestrictedAddButton';
import useDeepCompareEffect from 'use-deep-compare-effect';
import { ApplicationContext } from 'ui/context/ApplicationContext';

const Employees = () => {
    const [show, setShow] = React.useState(false);
    const [filters, setFilters] = React.useState(initialFilters);
    const [fileName, setFileName] = React.useState('');
    const [current, setCurrent] = React.useState<Employee | undefined>();
    const [pageable, setPageable] = React.useState<PageableEmployees>();
    const withSpinner = useSpinner();
    const isMounted = useIsMounted();
    const handleError = useErrorHandler();
    const { download } = useDownloadFile();

    const { state } = React.useContext(ApplicationContext);
    const { organization } = state;

    const toggleOverlay = React.useCallback(() => {
        setShow((value) => !value);
    }, []);

    const handleDownloadFile = React.useCallback(() => {
        download({
            url: `api/v1/employees/download?organizationId=${organization?.id}`,
            fileName: 'employees.xlsx',
        });
    }, [organization, download]);

    const rows = React.useMemo(
        () =>
            pageable?.result.map(({ id, firstName, lastName, uid, createdAt, organizationId }) => ({
                id,
                firstName,
                lastName,
                uid,
                createdAt,
                organizationId,
            })),
        [pageable],
    );

    // eslint-disable-next-line consistent-return
    const fetchEmployees = React.useCallback(async () => {
        try {
            const { pageSize, pageIndex, sort, columnFilters } = filters;

            const firstNameFilter = columnFilters.find((i) => i.id === 'firstName')?.value;
            const lastNameFilter = columnFilters.find((i) => i.id === 'lastName')?.value;
            const uidFilter = columnFilters.find((i) => i.id === 'uid')?.value;

            return await EmployeeService.getEmployees(
                pageSize,
                pageIndex,
                sort,
                firstNameFilter,
                lastNameFilter,
                uidFilter,
                organization?.id,
            );
        } catch (e) {
            console.error('An error occurred while fetching employees:', e);
            handleError();
        }
    }, [handleError, filters, organization?.id]);

    const handleFileUpload = React.useCallback(
        (event) => {
            const file = event.target.files?.[0];
            if (!file) return;

            setFileName(file.name);

            const fetchData = async () => {
                try {
                    await EmployeeService.postEmployeesUpload(organization.id, { file });
                    const pageableEmployees = await fetchEmployees();
                    if (isMounted) {
                        setPageable(pageableEmployees);
                    }
                } catch (error) {
                    console.error('File upload failed:', error);
                    handleError();
                }
            };

            withSpinner(fetchData);
        },
        [handleError, withSpinner, organization?.id, isMounted, fetchEmployees],
    );

    const handleDelete = React.useCallback(
        ({ original: { id } }) => {
            const callback = async () => {
                try {
                    await EmployeeService.deleteEmployees(id);

                    const pageableEmployees = await fetchEmployees();
                    if (isMounted) {
                        setPageable(pageableEmployees);
                    }
                } catch (e) {
                    console.error('An error occurred:', e);
                    handleError();
                }
            };
            withSpinner(callback);
        },
        [fetchEmployees, isMounted, withSpinner, handleError],
    );

    const handleUpdate = React.useCallback(
        ({ original: { id } }) => {
            setCurrent(pageable?.result.find((elem) => elem.id === id));
            toggleOverlay();
        },
        [pageable, toggleOverlay],
    );

    const actions = React.useMemo(
        () => ({
            UPDATE: { label: 'Ажурирај', callback: handleUpdate, roles: [Role.PM_MANAGER, Role.PM_ADMIN] },
            DELETE: { label: 'Избриши', callback: handleDelete, roles: [Role.PM_MANAGER, Role.PM_ADMIN] },
        }),
        [handleDelete, handleUpdate],
    );

    const columns = React.useMemo(
        () => [
            {
                accessorKey: 'firstName',
                header: 'Име',
                flex: 1,
            },
            {
                accessorKey: 'lastName',
                header: 'Презиме',
                flex: 1,
            },
            { accessorKey: 'uid', header: 'Шифра', flex: 1 },
            {
                accessorKey: 'createdAt',
                header: 'Датум',
                flex: 1,
                enableColumnFilter: false,
                accessorFn: ({ createdAt }) => formatDateToISOFormat(createdAt),
            },
            {
                accessorKey: 'actions',
                header: '',
                id: 'actions',
                enableColumnFilter: false,
                enableSorting: false,
                size: 1,
                Cell: ({ row }) => <ActionMenu params={row} actions={actions} />,
            },
        ],
        [actions],
    );

    const handleClose = React.useCallback(() => {
        setShow(false);
        setCurrent(undefined);
    }, []);

    const handleAddEmployee = React.useCallback(
        (values) => {
            const save = async () => {
                try {
                    const callback = values.id ? EmployeeService.putEmployees : EmployeeService.postEmployees;
                    await callback(values);

                    const pageableEmployees = await fetchEmployees();

                    if (isMounted) {
                        setPageable(pageableEmployees);
                    }
                } catch (e) {
                    console.error('An error occurred:', e);
                    handleError();
                } finally {
                    handleClose();
                }
            };
            withSpinner(save);
        },
        [fetchEmployees, isMounted, withSpinner, handleClose, handleError],
    );

    useDeepCompareEffect(() => {
        const initialize = async () => {
            try {
                const { pageSize, pageIndex, sort, columnFilters } = filters;
                const response: PageableEmployees = await EmployeeService.getEmployees(
                    pageSize,
                    pageIndex,
                    sort,
                    columnFilters.find((i) => i.id === 'firstName')?.value,
                    columnFilters.find((i) => i.id === 'lastName')?.value,
                    columnFilters.find((i) => i.id === 'uid')?.value,
                    organization?.id,
                );
                setPageable(response);
            } catch (e) {
                console.error('An error occurred:', e);
                handleError();
            }
        };
        withSpinner(initialize);
    }, [withSpinner, filters, handleError, organization?.id]);

    return (
        <>
            <div>
                <Button
                    component="label"
                    role={undefined}
                    variant="contained"
                    tabIndex={-1}
                    startIcon={<CloudDownloadIcon />}
                    onClick={handleDownloadFile}
                >
                    Превземи фајл
                </Button>
                <span>&nbsp;</span>
                <Button
                    component="label"
                    role={undefined}
                    variant="contained"
                    tabIndex={-1}
                    startIcon={<CloudUploadIcon />}
                >
                    Прикачи фајл
                    <HiddenInput type="file" onChange={handleFileUpload} />
                </Button>
                {fileName && (
                    <Typography component="span" style={{ marginTop: '10px' }}>
                        File Uploaded: {fileName}
                    </Typography>
                )}
            </div>
            <br />
            <AddEmployee open={show} handleClose={toggleOverlay} employee={current} onSubmit={handleAddEmployee} />

            <RestrictedAddButton
                onClick={toggleOverlay}
                title="Додади вработен"
                roles={[Role.PM_ADMIN, Role.PM_MANAGER]}
            />
            <TableComponent
                pageSize={filters.pageSize}
                pageIndex={filters.pageIndex}
                rowCount={pageable?.page?.totalElements}
                pageCount={pageable?.page?.totalPages}
                rows={rows}
                columns={columns}
                setFilters={setFilters}
            />
        </>
    );
};

export default Employees;

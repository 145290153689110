import React, { useCallback, useEffect, useRef } from 'react';
import { ApplicationContext } from 'ui/context/ApplicationContext';
import { showError } from 'ui//context/shared/actions';
import { useAuth } from 'react-oidc-context';
import { useSpinner } from 'ui/hooks/spinner';
import { downloadFile } from 'ui/utils/files';

export const useIsMounted = () => {
    const isMounted = useRef(true);

    useEffect(
        () =>
            // Component is mounted
            () => {
                // Component is unmounted
                isMounted.current = false;
            },
        [],
    );

    return isMounted.current;
};

export const useErrorHandler = () => {
    const { dispatch } = React.useContext(ApplicationContext);

    return React.useCallback(() => {
        dispatch(showError());
    }, [dispatch]);
};

export const useDownloadFile = () => {
    const auth = useAuth();
    const handleError = useErrorHandler();
    const withSpinner = useSpinner();

    const download = useCallback(
        ({ url, fileName }) => {
            const fetchData = async () => {
                try {
                    await downloadFile({
                        url,
                        fileName,
                        token: auth.user?.access_token,
                        handleError,
                    });
                } catch (error) {
                    handleError();
                }
            };

            withSpinner(fetchData);
        },
        [auth, handleError, withSpinner],
    );

    return { download };
};
